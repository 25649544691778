import React from 'react';
import { NavLink } from 'react-router-dom'

export default function Sidebar() {

    const _nav = [
        {
            name: 'Dashboard',
            route: '/',
            type: 'link',
        },
        {
            name: 'Internal Data',
            type: 'heading'
        },
        {
            name: 'Charities',
            route: '/charities',
            type: 'link',
        },
        {
            name: 'Obituaries',
            route: '/obituaries',
            type: 'link',
        },
        {
            name: 'Submissions',
            type: 'heading'
        },
        {
            name: 'Condolences',
            route: '/condolences',
            type: 'link',
        },
        {
            name: 'Donations',
            route: '/donations',
            type: 'link',
        },
    ]
    return (
        <div className="sidebar">
            <ul>
                {_nav.map((item) => {
                    switch(item.type) {
                        case 'link':
                            return <li className="link"><NavLink exact={true} activeClassName='is-active' to={item.route}>{item.name}</NavLink></li>
                        break;
                        case 'heading':
                            return <li className="heading"><h5>{item.name}</h5></li>
                        break;
                    }
                })}
            </ul>
        </div>
    )
}