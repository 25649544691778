import React, { useState } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams } from 'react-router-dom';
import { UpdatingNotification } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function AddNew() {

    const [updatingDB, setUpdatingDB] = useState(0);
    const [newName, setNewName] = useState(null);
    let { type } = useParams();

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            createNew()
        }
    }

    const createNew = () => {

        setUpdatingDB(1)

        apolloClient
            .mutate({
                mutation: gql`
                mutation mutateAddNew {
                    insert_${type}(objects: {Name: "${newName}"}) {
                        returning {
                            ID
                        }
                    }
                }`
            })
            .then(result => { 
                console.log(result);
                setUpdatingDB(0)
                if (result.data[`insert_${type}`].returning.length) {
                    // redirect using ID
                    const ID = result.data[`insert_${type}`].returning[0].ID;
                    window.location.href = `/${prettifyTypes(type).toLowerCase()}/${ID}`
                } else {
                    alert('There was an error creating that entry')
                }
            });
    }

    const prettifyTypes = (type) => {
        if (type === 'Deceased') {
            return 'Obituary'
        }
        return type
    }

    return (
        <Layout
            title={`Create New ${prettifyTypes(type)}:`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div className="col">
                    <h4>Enter {prettifyTypes(type)} name</h4>
                    <div className="editable-field">
                        <span className="value-field">
                            <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} onKeyDown={onKeyDown} />
                        </span>
                    </div>
                </div>
            </div>
        </Layout>
    )
}