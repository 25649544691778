import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import IconEdit from '../assets/images/icon_edit.svg'
import Layout from '../layout/Layout';
import { NavLink } from 'react-router-dom'
import { noNullValues, getSelectedNodeList, sortNodeListForExport } from '../helper/data-helper'

export default function Donations() {

    const [nodeList, setnodeList] = useState(null);
    const [charityList, setCharityList] = useState(null);
    const [deceasedList, setDeceasedList] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const [obituaryFilter, setObituaryFilter] = useState(null);
    const [charityFilter, setCharityFilter] = useState(null);
    const [dateFilterStart, setDateFilterStart] = useState(null);
    const [dateFilterEnd, setDateFilterEnd] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [exportedFilter, setExportedFilter] = useState(null);

    useEffect(() => {
        apolloClient
            .query({
                query: gql`
                query queryDonations {
                    Donations(order_by: {ID: asc}) {
                        ID
                        DateCreated
                        FirstName
                        LastName
                        Email
                        AddressLine1
                        AddressLine2
                        City
                        Country
                        PostalCode
                        Province
                        InMemoryOf
                        CharityID
                        DonationType
                        Exported
                        Charity {
                            Name
                        }
                        Deceased {
                            Name
                        }
                    }
                    Charity {
                        ID
                        Name
                    }
                    Deceased {
                        ID
                        Name
                    }
                }`
            })
            .then(result => { 
                setnodeList(result.data.Donations);
                setCharityList(result.data.Charity);
                setDeceasedList(result.data.Deceased);
            });
    }, []);

    const onSearch = (term) => {
        setSearchTerm(term)
    }

    let filteredList = nodeList;
    if (nodeList && searchTerm) {
        filteredList = filteredList.filter((data) => (data.FirstName && data.FirstName.includes(searchTerm)) || (data.LastName && data.LastName.includes(searchTerm)) || (data.Deceased.Name && data.Deceased.Name.includes(searchTerm)))
    }
    if (nodeList && typeFilter) {
        filteredList = filteredList.filter((data) => (data.DonationType && data.DonationType.includes(typeFilter)))
    }
    if (nodeList && charityFilter) {
        filteredList = filteredList.filter((data) => (data.CharityID && Number(data.CharityID) === Number(charityFilter)))
    }
    if (nodeList && obituaryFilter) {
        filteredList = filteredList.filter((data) => (data.InMemoryOf && Number(data.InMemoryOf) === Number(obituaryFilter)))
    }
    if (nodeList && exportedFilter) {
        filteredList = filteredList.filter((data) => (Boolean(data.Exported) !== true))
    }
    if (nodeList && dateFilterStart) {
        filteredList = filteredList.filter((data) => { 
            var d1 = new Date(dateFilterStart);
            var d2 = new Date(data.DateCreated);
           return d1.getTime() <= d2.getTime();
        });
    }
    if (nodeList && dateFilterEnd) {
        filteredList = filteredList.filter((data) => { 
            var d1 = new Date(dateFilterEnd);
            var d2 = new Date(data.DateCreated);
           return d1.getTime() >= d2.getTime();
        });
    }


    const onSelectAll = () => {
        if (!selectAll) {
            setSelectAllNodes(true)
            setSelectAll(true)
        } else {
            setSelectAllNodes(false)
            setSelectAll(false)
        }
    }

    const setSelectAllNodes = (state) => {
        let newnodeList = JSON.parse(JSON.stringify(nodeList))
        newnodeList.map((data, node) => {
            newnodeList[node].selected = state;
        })
        setnodeList(newnodeList)
    }

    const selectNode = (node) => {
        let newnodeList = JSON.parse(JSON.stringify(nodeList))
        if (!newnodeList[node].selected) {
            newnodeList[node].selected = true;
        } else {
            newnodeList[node].selected = false;
        }
        setnodeList(newnodeList)
    }

    const onFilterType = (term) => {
        setTypeFilter(term)
    }

    const onFilterObituary = (term) => {
        setObituaryFilter(term)
    }

    const onFilterCharity = (term) => {
        setCharityFilter(term)
    }
    const onFilterExported = (term) => {
        setExportedFilter(term)
    }

    return (
        <Layout
            title={'Donations'}
            search={true}
            onSearch={onSearch}
            onExport={() => sortNodeListForExport(getSelectedNodeList(nodeList))}
            onExportPDF={() => sortNodeListForExport(getSelectedNodeList(nodeList))}
            exportTemplate={'donations'}
            addNew={'addNewSubmission/Donations'}
            filter={
                [
                    {key: `Type`, value: typeFilter, values: [{ID: `cash`, Name: `cash`}, {ID: `cheque`, Name: `cheque`}, {ID: `credit`, Name: `credit`},  {ID: `none`, Name: `none`}], callback: onFilterType}, 
                    {key: `Obituary`, value: obituaryFilter, values: deceasedList, callback: onFilterObituary},
                    {key: `Charity`, value: charityFilter, values: charityList, callback: onFilterCharity},
                    {key: `Exported`, value: exportedFilter, values: [
                        {ID: true, Name: `Not Exported`},
                    ], callback: onFilterExported},
                ]}
        >
            <>
            <DateFilter 
                dateFilterStart={dateFilterStart}
                setDateFilterStart={setDateFilterStart}
                dateFilterEnd={dateFilterEnd}
                setDateFilterEnd={setDateFilterEnd}
            />
            <div className="tabular-data two-col">
                <div className="row row-heading">
                    <div className="col small">
                        <input type="checkbox" onChange={onSelectAll}></input>
                    </div>
                    <div className="col medium">
                        <h4>ID</h4>
                    </div>
                    <div className="col">
                        <h4>Date Created</h4>
                    </div>
                    <div className="col">
                        <h4>In Memory Of</h4>
                    </div>
                    <div className="col">
                        <h4>Name</h4>
                    </div>
                    <div className="col">
                        <h4>Email</h4>
                    </div>
                    <div className="col">
                        <h4>Exported</h4>
                    </div>
                    <div className="col small">
             
                    </div>
                </div>
                {nodeList ? filteredList.map((data, i) => {

                    return (
                        <div className="row">
                            <div className="col small">
                                <input type="checkbox" onChange={(e) => selectNode(i)} checked={data.selected ? 'checked' : false}></input>
                            </div>
                            <div className="col medium">
                                <p># {noNullValues(data.ID)}</p>
                            </div>
                            <div className="col">
                                <p>{noNullValues(data.DateCreated)}</p>
                            </div>
                            <div className="col">
                                <p>{noNullValues(data.Deceased.Name)}</p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.FirstName)} {noNullValues(data.LastName)}
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.Email)}
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                {data.Exported ? 'Yes' : 'No'}
                                </p>
                            </div>
                            <div className="col small">
                                <NavLink exact={true} activeClassName='is-active' to={`donation/${data.ID}`}><img src={IconEdit} className="edit" /></NavLink>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
            </>
        </Layout>
    )
}

const DateFilter = ({dateFilterStart, dateFilterEnd, setDateFilterStart, setDateFilterEnd}) => {

    return (
        <div className="filter-by-date-wrap">
            <label>Filter by Date:</label>
            <div className="filter-by-date">
                <div>
                    <label for="start">Start date:</label>
                    <input type="date" id="start" name="start_date"
                        value={dateFilterStart}
                        onChange={(e) => setDateFilterStart(e.target.value)}
                    ></input>
                </div>
                <div>
                    <label for="end">End date:</label>
                    <input type="date" id="end" name="end_date"
                        value={dateFilterEnd}
                        onChange={(e) => setDateFilterEnd(e.target.value)}
                    ></input>
                </div>
            </div>
        </div>
    );
}