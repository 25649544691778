import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams } from 'react-router-dom';
import { noNullValues, EditableField, UpdatingNotification } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function Charity() {

    const [node, setNode] = useState(null);
    const [updateDB, setUpdateDB] = useState(0);
    const [updatingDB, setUpdatingDB] = useState(0);
    let { ID } = useParams();

    // pull data on load
    useEffect(() => {
        apolloClient
            .query({
                query: gql`
                query queryCharities {
                    Charity(where: {ID: {_eq: ${ID}}}) {
                        AddressLine1
                        AddressLine2
                        City
                        ContactFirstName
                        ContactLastName
                        Country
                        Email
                        Fax
                        ID
                        Name
                        Phone
                        PostalCode
                        Province
                        TollFreePhone
                        Website
                      }
                }`
            })
            .then(result => { 
                setNode(result.data.Charity[0]);
            });
    }, []);

    // update DB data when state is changed
    useEffect(() => {

        if (node && updateDB) {
            // take node data and assemble into mutation
            let graphQLQuery = ''
            Object.keys(node).map((key, i) => {
                const field = node[key]
                if (key !== '__typename' 
                    && key !== 'ID' 
                    && field && field !== 'NULL') {
                    graphQLQuery += `${key}: "${field}", `;
                }
            })

            // start loading state
            setUpdatingDB(1)

            apolloClient
                .mutate({
                    mutation: gql`
                    mutation mutateCharityById {
                    update_Charity(where: {ID: {_eq: ${ID}}}, _set: {${graphQLQuery}}) {
                        affected_rows
                    }
                }`
                })
                .then(result => { 
                    setUpdatingDB(0)
                }); 

            setUpdateDB(0);
        }
    }, [updateDB]);

    const updateField = (key, value) => {
        let newNode = JSON.parse(JSON.stringify(node))
        newNode[key] = value;
        setNode(newNode);
    }

    if (!node) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <Layout
            title={node ? `Edit Charity: ${noNullValues(node.Name)}` : 'Edit Charity'}
            deleteThisEntry={`/deleteEntryWithChildren/Charity/${node.ID}`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div className="col no-edit">
                    <h4>ID:</h4>
                    <EditableField
                        update={(e) => updateField('ID', e.target.value)}
                        value={node.ID}
                        save={setUpdateDB}
                        editable={false}
                    >
                        {noNullValues(node.ID)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Name:</h4>
                    <EditableField
                        update={(e) => updateField('Name', e.target.value)}
                        value={node.Name}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Name)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Address Line 1:</h4>
                    <EditableField
                        update={(e) => updateField('AddressLine1', e.target.value)}
                        value={node.AddressLine1}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.AddressLine1)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Address Line 2:</h4>
                    <EditableField
                        update={(e) => updateField('AddressLine2', e.target.value)}
                        value={node.AddressLine2}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.AddressLine2)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>City:</h4>
                    <EditableField
                        update={(e) => updateField('City', e.target.value)}
                        value={node.City}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.City)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Postal Code:</h4>
                    <EditableField
                        update={(e) => updateField('PostalCode', e.target.value)}
                        value={node.PostalCode}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.PostalCode)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Country:</h4>
                    <EditableField
                        update={(e) => updateField('Country', e.target.value)}
                        value={node.Country}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Country)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Phone:</h4>
                    <EditableField
                        update={(e) => updateField('Phone', e.target.value)}
                        value={node.Phone}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Phone)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Fax:</h4>
                    <EditableField
                        update={(e) => updateField('Fax', e.target.value)}
                        value={node.Fax}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Fax)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Toll-Free Phone:</h4>
                    <EditableField
                        update={(e) => updateField('TollFreePhone', e.target.value)}
                        value={node.TollFreePhone}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.TollFreePhone)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Email:</h4>
                    <EditableField
                        update={(e) => updateField('Email', e.target.value)}
                        value={node.Email}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Email)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Website:</h4>
                    <EditableField
                        update={(e) => updateField('Website', e.target.value)}
                        value={node.Website}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Website)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Contact First Name:</h4>
                    <EditableField
                        update={(e) => updateField('ContactFirstName', e.target.value)}
                        value={node.ContactFirstName}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.ContactFirstName)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Contact Last Name:</h4>
                    <EditableField
                        update={(e) => updateField('ContactLastName', e.target.value)}
                        value={node.ContactLastName}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.ContactLastName)}
                    </EditableField>
                </div>
            </div>
        </Layout>
    )
}