import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams } from 'react-router-dom';
import { noNullValues, EditableField, UpdatingNotification, formatDate, getRowByID } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function Donation() {

    const [node, setNode] = useState(null);
    const [updateDB, setUpdateDB] = useState(0);
    const [updatingDB, setUpdatingDB] = useState(0);
    const [charities, setCharities] = useState(null);
    const [deceaseds, setDeceased] = useState(null);
    const [inMemoryOf, setInMemoryOf] = useState(null);
    const [CharityID, setCharityID] = useState(null);
    let { ID } = useParams();

    // pull data on load
    useEffect(() => {
        apolloClient
            .query({
                query: gql`
                query queryDonation {
                    Donations(where: {ID: {_eq: ${ID}}}) {
                        AddressLine1
                        AddressLine2
                        City
                        Country
                        Email
                        FirstName
                        ID
                        LastName
                        Phone
                        PostalCode
                        Province
                        InMemoryOf
                        CharityID
                        DonationType
                        Deceased {
                            Name
                        }
                        Charity {
                            Name
                        }
                      }
                      Charity {
                        ID
                        Name
                      }
                      Deceased {
                          Name
                          ID
                      }
                }`
            })
            .then(result => { 
                setNode(result.data.Donations[0]);
                if (result.data.Charity.length) {
                    setCharities(result.data.Charity);
                    setCharityID(getRowByID(result.data.Donations[0].CharityID, result.data.Charity));
                }
                if (result.data.Deceased.length) {
                    setDeceased(result.data.Deceased);
                    setInMemoryOf(getRowByID(result.data.Donations[0].InMemoryOf, result.data.Deceased));
                }
            });
    }, []);

    // update DB data when state is changed
    useEffect(() => {

        if (node && updateDB) {
            // take node data and assemble into mutation
            let graphQLQuery = ''
            Object.keys(node).map((key, i) => {
                let field = node[key]
                // format dates
                if (field instanceof Date) {
                    field = formatDate(new Date(field));
                }
                // format strings
                if (key !== '__typename' 
                    && key !== 'ID' 
                    && key !== 'Donations'
                    && key !== 'Deceased'
                    && key !== 'Charity'
                    && field && field !== 'NULL') {
                    graphQLQuery += `${key}: "${field}", `;
                }
            })

            // start loading state
            setUpdatingDB(1)

            apolloClient
                .mutate({
                    mutation: gql`
                    mutation mutateDonationsById {
                        update_Donations(where: {ID: {_eq: ${ID}}}, _set: {${graphQLQuery}}) {
                            affected_rows
                        }
                }`
                })
                .then(result => { 
                    setUpdatingDB(0)
                }); 

            setUpdateDB(0);
        }
    }, [updateDB]);

    // update dynamic if changed
    useEffect(() => {
        if (node) {
            setInMemoryOf(getRowByID(parseInt(node.InMemoryOf), deceaseds));
            setCharityID(getRowByID(parseInt(node.CharityID), charities));
        }
    }, [node])

    const updateField = (key, value, toString = false) => {
        let newNode = JSON.parse(JSON.stringify(node))
        newNode[key] = value;
        setNode(newNode);
    }

    if (!node) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <Layout
            title={node ? `Edit Donation Entry: #${noNullValues(node.ID)}` : 'Edit Donation Entry'}
            deleteThisEntry={`/deleteEntry/Donations/${node.ID}`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div className="col no-edit">
                    <h4>ID:</h4>
                    <EditableField
                        update={(e) => updateField('ID', e.target.value)}
                        value={node.ID}
                        save={setUpdateDB}
                        editable={false}
                    >
                        {noNullValues(node.ID)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>In Memory Of:</h4>
                    {node.InMemoryOf && inMemoryOf ? (
                        <EditableField
                            value={node.InMemoryOf}
                            values={deceaseds}
                            update={(e) => updateField('InMemoryOf', e.target.value)}
                            save={setUpdateDB}
                            editable={true}
                            type={'select_map'}
                        >
                            {noNullValues(inMemoryOf.Name)}
                        </EditableField>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <div className="col">
                    <h4>Charity:</h4>
                    {node.CharityID && CharityID ? (
                        <EditableField
                            value={node.CharityID}
                            values={charities}
                            update={(e) => updateField('CharityID', e.target.value)}
                            save={setUpdateDB}
                            editable={true}
                            type={'select_map'}
                        >
                            {noNullValues(CharityID.Name)}
                        </EditableField>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <div className="col">
                    <h4>Donation Type:</h4>
                    <EditableField
                        update={(e) => updateField('DonationType', e.target.value)}
                        value={node.DonationType}
                        type={`select`}
                        save={setUpdateDB}
                        editable={true}
                        values={['cash', 'cheque', 'credit card', 'no payment']}
                    >
                        {noNullValues(node.DonationType)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>First Name:</h4>
                    <EditableField
                        update={(e) => updateField('FirstName', e.target.value)}
                        value={node.FirstName}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.FirstName)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Last Name:</h4>
                    <EditableField
                        update={(e) => updateField('LastName', e.target.value)}
                        value={node.LastName}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.LastName)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Email:</h4>
                    <EditableField
                        update={(e) => updateField('Email', e.target.value)}
                        value={node.Email}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Email)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Phone:</h4>
                    <EditableField
                        update={(e) => updateField('Phone', e.target.value)}
                        value={node.Phone}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Phone)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Address Line 1:</h4>
                    <EditableField
                        update={(e) => updateField('AddressLine1', e.target.value)}
                        value={node.AddressLine1}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.AddressLine1)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Address Line 2:</h4>
                    <EditableField
                        update={(e) => updateField('AddressLine2', e.target.value)}
                        value={node.AddressLine2}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.AddressLine2)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>City:</h4>
                    <EditableField
                        update={(e) => updateField('City', e.target.value)}
                        value={node.City}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.City)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Province:</h4>
                    <EditableField
                        update={(e) => updateField('Province', e.target.value)}
                        value={node.Province}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Province)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Postal Code:</h4>
                    <EditableField
                        update={(e) => updateField('PostalCode', e.target.value)}
                        value={node.PostalCode}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.PostalCode)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Country:</h4>
                    <EditableField
                        update={(e) => updateField('Country', e.target.value)}
                        value={node.Country}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Country)}
                    </EditableField>
                </div>
            </div>
        </Layout>
    )
}