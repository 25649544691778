import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams } from 'react-router-dom';
import { noNullValues, EditableField, UpdatingNotification, formatDate } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function Obituary() {

    const [node, setNode] = useState(null);
    const [updateDB, setUpdateDB] = useState(0);
    const [updatingDB, setUpdatingDB] = useState(0);
    let { ID } = useParams();

    // set date fields

    // pull data on load
    useEffect(() => {
        apolloClient
            .query({
                query: gql`
                query queryObituary {
                    Deceased(where: {ID: {_eq: ${ID}}}) {
                        DateofBirth
                        FamilyFirstName
                        FamilyLastName
                        FuneralDate
                        ID
                        Name
                        Obituary
                      }
                }`
            })
            .then(result => { 
                setNode(result.data.Deceased[0]);
            });
    }, []);

    // update DB data when state is changed
    useEffect(() => {

        if (node && updateDB) {
            // take node data and assemble into mutation
            let graphQLQuery = ''
            Object.keys(node).map((key, i) => {
                let field = node[key]
                // format dates
                if (field instanceof Date) {
                    field = formatDate(new Date(field));
                }
                // format strings
                if (key !== '__typename' 
                    && key !== 'ID' 
                    && key !== 'Donations'
                    && field && field !== 'NULL') {
                    graphQLQuery += `${key}: "${field}", `;
                }
            })

            // start loading state
            setUpdatingDB(1)

            apolloClient
                .mutate({
                    mutation: gql`
                    mutation mutateDeceasedById {
                        update_Deceased(where: {ID: {_eq: ${ID}}}, _set: {${graphQLQuery}}) {
                            affected_rows
                        }
                }`
                })
                .then(result => { 
                    setUpdatingDB(0)
                }); 

            setUpdateDB(0);
        }
    }, [updateDB]);

    const updateField = (key, value, toString = false) => {
        let newNode = JSON.parse(JSON.stringify(node))
        newNode[key] = value;
        setNode(newNode);
    }

    if (!node) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <Layout
            title={node ? `Edit Obituary: ${noNullValues(node.Name)}` : 'Edit Obituary'}
            deleteThisEntry={`/deleteEntryWithChildren/Deceased/${node.ID}`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div className="col no-edit">
                    <h4>ID:</h4>
                    <EditableField
                        update={(e) => updateField('ID', e.target.value)}
                        value={node.ID}
                        save={setUpdateDB}
                        editable={false}
                    >
                        {noNullValues(node.ID)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Name:</h4>
                    <EditableField
                        update={(e) => updateField('Name', e.target.value)}
                        value={node.Name}
                        save={setUpdateDB}
                    >
                        {noNullValues(node.Name)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Date of Birth:</h4>
                    <EditableField
                        update={(e) => updateField('DateofBirth', e)}
                        value={node.DateofBirth}
                        save={setUpdateDB}
                        type={`date`}
                    >
                        {noNullValues(node.DateofBirth)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Date of Death:</h4>
                    <EditableField
                        update={(e) => updateField('DateofDeath', e)}
                        value={node.DateofDeath}
                        save={setUpdateDB}
                        type={`date`}
                    >
                        {noNullValues(node.DateofDeath)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Date of Funeral:</h4>
                    <EditableField
                        update={(e) => updateField('FuneralDate', e)}
                        value={node.FuneralDate}
                        save={setUpdateDB}
                        type={`date`}
                    >
                        {noNullValues(node.FuneralDate)}
                    </EditableField>
                </div>
                <div className="col">
                    <h4>Obituary: </h4>
                    <EditableField
                        update={(e) => updateField('Obituary', e.target.value)}
                        value={node.Obituary}
                        save={setUpdateDB}
                        type={`textarea`}
                    >
                        {noNullValues(node.Obituary)}
                    </EditableField>
                </div>
            </div>
        </Layout>
    )
}