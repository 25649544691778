import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams, useHistory } from 'react-router-dom';
import { UpdatingNotification } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function DeleteEntry() {

    const [updatingDB, setUpdatingDB] = useState(0);
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(`green`);
    let { type, ID } = useParams();

    const history = useHistory();

    useEffect(() => {
        setUpdatingDB(1)

        apolloClient
            .mutate({
                mutation: gql`
                mutation mutateDeleteEntry {
                    delete_${type}(where: {ID: {_eq: ${ID}}}) {
                        affected_rows
                    }
                }`
            })
            .then(result => { 
                setUpdatingDB(0)
                if (result.data[`delete_${type}`].affected_rows) {
                    // action successful
                    setMessage('Entry deleted. Redirecting...')
                    setTimeout(() => {
                        history.push(`/${prettifyTypes(type)}`)
                    }, 900)
                    setStatus('green')
                } else {
                    setStatus('red')
                    setMessage('There was an error deleting this')
                }
            });
    }, [])

    const prettifyTypes = (type) => {
        if (type === 'Deceased') {
            return 'Obituary'
        }
        return type
    }

    return (
        <Layout
            title={`Deleting ${prettifyTypes(type)}...`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div>
                    <h4><em>Deleting {prettifyTypes(type)} with ID of {ID}</em></h4>
                    {message ? (
                        <div className={`notification ${status}`}>
                            {message}
                        </div>
                    ) : null}
                </div>
            </div>
        </Layout>
    )
}