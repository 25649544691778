require('dotenv').config()
const fetch = require('cross-fetch');
const { ApolloClient, InMemoryCache, HttpLink } = require('@apollo/client/core');

const createApolloClient = () => {

    const API = process.env.API_URL || 'donations.macleanfh.ca'
    const AUTH = process.env.AUTH_SECRET || 'P#Icot2Aqa-?T#abrEg@'

    return new ApolloClient({
        link: new HttpLink({
        uri: `https://${API}/v1/graphql`,
        headers: {
            'x-hasura-access-key': `${AUTH}`,
        },
        fetch,
        onError: ({ networkError, graphQLErrors }) => {
            console.log('graphQLErrors', graphQLErrors)
            console.log('networkError', networkError)
        }
        }),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
            },
        }
    });
};

module.exports = createApolloClient();