import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import IconEdit from '../assets/images/icon_edit.svg'
import Layout from '../layout/Layout';
import { NavLink } from 'react-router-dom'
import { noNullValues, getSelectedNodeList } from '../helper/data-helper'

export default function Condolences() {

    const [nodeList, setnodeList] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [deceasedList, setDeceasedList] = useState(null);
    const [obituaryFilter, setObituaryFilter] = useState(null);
    const [exportedFilter, setExportedFilter] = useState(null);

    useEffect(() => {
        apolloClient
            .query({
                query: gql`
                query queryCondolences {
                    Condolences(order_by: {ID: asc}) {
                        ID
                        FirstName
                        LastName
                        Email
                        Phone
                        Message
                        AddressLine1
                        AddressLine2
                        City
                        Province
                        PostalCode
                        Country
                        InMemoryOf
                        Exported
                        Deceased {
                            Name
                        }
                    }
                    Deceased {
                        ID
                        Name
                    }
                }`
            })
            .then(result => { 
                setnodeList(result.data.Condolences);
                setDeceasedList(result.data.Deceased);
            });
    }, []);

    const onSearch = (term) => {
        setSearchTerm(term)
    }

    let filteredList = nodeList;
    if (nodeList && searchTerm) {
        filteredList = filteredList.filter((data) => (data.FirstName && data.FirstName.includes(searchTerm)) || (data.LastName && data.LastName.includes(searchTerm)) || (data.Deceased.Name && data.Deceased.Name.includes(searchTerm)))
    }
    if (nodeList && obituaryFilter) {
        filteredList = filteredList.filter((data) => (data.InMemoryOf && Number(data.InMemoryOf) === Number(obituaryFilter)))
    }
    if (nodeList && exportedFilter) {
        filteredList = filteredList.filter((data) => (Boolean(data.Exported) !== true))
    }


    const onSelectAll = () => {
        if (!selectAll) {
            setSelectAllNodes(true)
            setSelectAll(true)
        } else {
            setSelectAllNodes(false)
            setSelectAll(false)
        }
    }

    const setSelectAllNodes = (state) => {
        let newnodeList = JSON.parse(JSON.stringify(nodeList))
        newnodeList.map((data, node) => {
            newnodeList[node].selected = state;
        })
        setnodeList(newnodeList)
    }

    const selectNode = (node) => {
        let newnodeList = JSON.parse(JSON.stringify(nodeList))
        if (!newnodeList[node].selected) {
            newnodeList[node].selected = true;
        } else {
            newnodeList[node].selected = false;
        }
        setnodeList(newnodeList)
    }

    const onFilterObituary = (term) => {
        setObituaryFilter(term)
    }

    const onFilterExported = (term) => {
        setExportedFilter(term)
    }

    return (
        <Layout
            title={'Condolences'}
            search={true}
            onSearch={onSearch}
            filter={true}
            onExport={() => getSelectedNodeList(nodeList)}
            onExportPDF={() => getSelectedNodeList(nodeList)}
            exportTemplate={'condolences'}
            addNew={'addNewSubmission/Condolences'}
            filter={
                [
                    {key: `Obituary`, value: obituaryFilter, values: deceasedList, callback: onFilterObituary},
                    {key: `Exported`, value: exportedFilter, values: [
                        {ID: true, Name: `Not Exported`},
                    ], callback: onFilterExported}
                ]}     
        >
            <div className="tabular-data two-col">
                <div className="row row-heading">
                    <div className="col small">
                        <input type="checkbox" onChange={onSelectAll}></input>
                    </div>
                    <div className="col medium">
                        <h4>ID</h4>
                    </div>
                    <div className="col">
                        <h4>In Memory Of</h4>
                    </div>
                    <div className="col">
                        <h4>Name</h4>
                    </div>
                    <div className="col">
                        <h4>Email</h4>
                    </div>
                    <div className="col">
                        <h4>Exported</h4>
                    </div>
                    <div className="col small">
             
                    </div>
                </div>
                {nodeList ? filteredList.map((data, i) => {

                    return (
                        <div className="row">
                            <div className="col small">
                                <input type="checkbox" onChange={(e) => selectNode(i)} checked={data.selected ? 'checked' : false}></input>
                            </div>
                            <div className="col medium">
                                <p># {noNullValues(data.ID)}</p>
                            </div>
                            <div className="col">
                                <p>{noNullValues(data.Deceased.Name)}</p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.FirstName)} {noNullValues(data.LastName)}
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.Email)}
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                {data.Exported ? 'Yes' : 'No'}
                                </p>
                            </div>
                            <div className="col small">
                                <NavLink exact={true} activeClassName='is-active' to={`condolence/${data.ID}`}><img src={IconEdit} className="edit" /></NavLink>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
        </Layout>
    )
}