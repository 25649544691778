import React, { useState } from 'react';
import axios from "axios";

export default function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const _submitLogin = () => {
        axios.post(
            `/authorize`,
            {},
            { headers: {
                usr: username,
                authorization: password
            }},
        )
        .then(response => {
            if (response.data.message === "Success") {
                document.cookie = response.data.cookie
                window.location = '/'
            }
         })
         .catch(error => {
             alert('There was an error')
             console.log(error)
         });
    }

    return (
        <div className="page">
            <h1>Log in</h1>
            <div className="login-container">
                <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button type="submit" className="button primary" onClick={_submitLogin}>Log In</button>
            </div>
        </div>
    )
}