import React, { useEffect, useState } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams } from 'react-router-dom';
import { UpdatingNotification } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function AddNewSubmission() {

    const [updatingDB, setUpdatingDB] = useState(0);
    const [charityID, setCharityID] = useState(null);
    const [charities, setCharities] = useState(null);
    const [deceasedID, setDeceasedID] = useState(null);
    const [deceaseds, setDeceased] = useState(null);

    let { type } = useParams();

    // on init, load
    useEffect(() => {
        // load charities
        apolloClient
            .query({
                query: gql`
                query GetFieldData {
                    Charity {
                      ID
                      Name
                    }
                    Deceased {
                        Name
                        ID
                    }
                  }`
            })
            .then(result => { 
                console.log(result);
                if (result.data.Charity.length) {
                    setCharities(result.data.Charity);
                    setCharityID(result.data.Charity[0].ID);
                }
                if (result.data.Deceased.length) {
                    setDeceased(result.data.Deceased);
                    setDeceasedID(result.data.Deceased[0].ID);
                }
            });
    }, []);


    const onClick = (e) => {
        createNew()
    }

    const createNew = () => {

        setUpdatingDB(1)
        
        const dateCreated = new Date().toISOString().slice(0,10);
        const charityField = type === 'Donations' ? `CharityID: ${charityID},` : ''

        try {
            apolloClient
                .mutate({
                    mutation: gql`
                    mutation mutateAddNew {
                        insert_${type}(objects: {DateCreated: "${dateCreated}", ${charityField} InMemoryOf: ${deceasedID}, FirstName: "", LastName: ""}) {
                            returning {
                                ID
                            }
                        }
                    }`
                })
                .then(result => { 
                    console.log(result);
                    setUpdatingDB(0)
                    if (result.data[`insert_${type}`].returning.length) {
                        // redirect using ID
                        const ID = result.data[`insert_${type}`].returning[0].ID;
                        window.location.href = `/${prettifyTypes(type).toLowerCase()}/${ID}`
                    } else {
                        alert('There was an error creating that entry')
                    }
                });
        }catch(e) {
            alert('There was an error. Please confirm that all fields are selected')
        }
    }

    const prettifyTypes = (type) => {
        if (type === 'Deceased') {
            return 'Obituary'
        }
        if (type === 'Donations') {
            return 'Donation'
        }
        if (type === 'Condolences') {
            return 'Condolence'
        }
        return type
    }

    return (
        <Layout
            title={`Create New ${prettifyTypes(type)}:`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div className="col">
                    <h4>Select In Memory Of</h4>
                    <div className="editable-field">
                        <span className="value-field">
                            <select value={deceasedID} onChange={(e) => setDeceasedID(e.target.value)}>
                                {deceaseds ? deceaseds.map((deceased) => (
                                    <option value={deceased.ID}>{deceased.Name}</option>
                                )) : null}
                            </select>
                        </span>
                    </div>
                </div>
                {type === 'Donations' ? (
                    <div className="col">
                        <h4>Select Charity</h4>
                        <div className="editable-field">
                            <span className="value-field">
                                <select value={charityID} onChange={(e) => setCharityID(e.target.value)}>
                                    {charities ? charities.map((charity) => (
                                        <option value={charity.ID}>{charity.Name}</option>
                                    )) : null}
                                </select>
                            </span>
                        </div>
                    </div>
                ) : null}
                <div className="col">
                    <button type="submit" className="button" onClick={onClick}>Create New {prettifyTypes(type)}</button>
                </div>
            </div>
        </Layout>
    )
}