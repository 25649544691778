import React from 'react';
import Layout from '../layout/Layout';

export default function Dashboard() {
    return (
        <Layout
            title={'Dashboard'}
        >
            content
        </Layout>
    )
}