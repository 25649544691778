import React, {useEffect, useState} from 'react';
import { NavLink, useHistory } from 'react-router-dom'
import { getObituaries, exportCSV, exportPDF, SelectField } from '../helper/data-helper'
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';

export default function Layout({title, addNew, children, search, onSearch, onExport, onExportPDF, deleteThisEntry, exportTemplate, filter}) {

    const [obituaries, setObituaries] = useState(null)
    const history = useHistory();

    const deleteThis = () => {
        let confirmation = window.confirm("You are about to delete this entry. This cannot be undone. Are you sure you want to proceed?")
        if (confirmation == true) {
            // proceed on deleting
            history.push(deleteThisEntry);
        }
    }

    return (
        <>
            <div className="page">
                <div className="page-header">
                    <h1>{title}</h1>
                    {filter ? 
                        filter.map((param) => {
                            return (
                                <SelectField
                                    label={`Filter by ${param.key}`}
                                    value={param.value}
                                    values={param.values}
                                    update={(e) => param.callback(e.target.value)}
                                />
                            )
                        })
                     : null}
                    {search ? (
                        <input className="search" type="text" placeholder="Filter By Keyword..." onChange={(e) => onSearch(e.target.value)} />
                    ) : null}
                    {deleteThisEntry ? (
                        <button className="button error" onClick={deleteThis}>Delete</button>
                    ) : null}
                </div>
                <div className="content">
                    {addNew ? (
                        <div className="content-control">
                            <NavLink exact={true}  to={addNew}><button className="button primary">Create New</button></NavLink>
                            
                        </div>
                    ) : null}
                    {children}
                </div>
            </div>
            <div className="controls">
                {onExport ? (
                    <ExportFeature list={onExport} />
                ) : null}
                {onExportPDF ? (
                    <ExportPDFFeature template={exportTemplate} list={onExportPDF} />
                ) : null}
            </div>
        </>
    )
}

const SelectFilter = async ({values, onSelect}) => {
    const [value, setValue] = useState(null);
    return (
        <select>
            {values ? values.map((val) => {
                <option val={val.ID} selected={val.ID === value ? 'selected' : false}>{val.Name}</option>
            }) : null}
        </select>
    )
}

const ExportFeature = ({list}) => {

    const doExport = () => {
        const rows = list();
        if (rows.length) {
            exportCSV(list())
        } else {
            alert('You can\'t export, you have nothing selected')
        }
    }

    return (
        <button className="export button secondary" onClick={doExport}>Export Selected</button>
    )
}

const ExportPDFFeature = ({template, list}) => {

    const doExport = () => {
        const rows = list();
        if (rows.length) {
            // update the list as 'exported'
            updateRowsAsExport(template, rows);
            // export the pdf
            exportPDF(template, rows)
        } else {
            alert('You can\'t export, you have nothing selected')
        }
    }

    return (
        <button className="export button secondary" onClick={doExport}>Generate PDF of Selected</button>
    )
}

const updateRowsAsExport = (type, rows) => {
    rows.map((row) => {
        // update each row with a query
        apolloClient
            .mutate({
                mutation: gql`
                mutation updateExported {
                    update_${type.capitalize()}(_set: {Exported: true}, where: {ID: {_eq: ${row.ID}}}) {
                        affected_rows
                    }
                }`
            })
            .then(result => { 
                //console.log('result', result.data)
            });
    })
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}