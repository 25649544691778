import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { useParams, useHistory } from 'react-router-dom';
import { UpdatingNotification } from '../helper/data-helper'
import { noNullValues, EditableField, getRowByID } from '../helper/data-helper'

import Layout from '../layout/Layout';

export default function DeleteEntryWithChildren() {

    const [updatingDB, setUpdatingDB] = useState(0);
    const [message, setMessage] = useState(null);
    const [newNode, setNewNode] = useState(null);
    const [condolences, setCondolences] = useState(null);
    const [donations, setDonations] = useState(null);
    const [charities, setCharities] = useState(null);
    const [deceaseds, setDeceaseds] = useState(null);
    const [attributionRequired, setAttributionRequired] = useState(null);
    const [status, setStatus] = useState(`green`);
    let { type, ID } = useParams();

    const history = useHistory();

    useEffect(() => {
        setUpdatingDB(1)

        let graphqlq =  `
        Condolences(where: {InMemoryOf: {_eq: ${ID}}}) {
            ID
            FirstName
            LastName
        }
        Donations(where: {InMemoryOf: {_eq: ${ID}}}) {
            ID
            FirstName
            LastName
        }
        `

        if (type === 'Charity') {
            graphqlq =  `
            Donations(where: {CharityID: {_eq: ${ID}}}) {
                ID
                FirstName
                LastName
            }
            `
        }

        apolloClient
            .query({
                query: gql`
                query checkForChildren {
                    ${graphqlq}
                    Deceased {
                        ID
                        Name
                    }
                    Charity {
                        ID
                        Name
                    }
                }`
            })
            .then(result => { 
                setUpdatingDB(0)

                if ((type === 'Deceased' && !result.data.Condolences.length && !result.data.Donations.length)
                    || (type === 'Charity' && !result.data.Donations.length)) {
                    // there are no children. Just delete
                    deleteOnly(ID)
                } else {
                    if (type === 'Deceased') {
                        if (result.data.Condolences.length) {
                            setCondolences(result.data.Condolences)
                        }
                    }
                    if (result.data.Donations.length) {
                        setDonations(result.data.Donations)
                    }
                    if (result.data.Deceased.length) {
                        setDeceaseds(result.data.Deceased)
                    }
                    if (result.data.Charity.length) {
                        setCharities(result.data.Charity)
                    }
                    
                    setAttributionRequired(true);
                    setStatus('red')
                    setMessage(`This entry has decendents and they will need to be re-assigned before you delete it. You can re-assign them to another entry below.`)
                }
            });
    }, [])

    const deleteWithDependents = () => {
        if (!newNode) {
            alert('You need to select options to attribute entries to');
            return
        }

        let graphqlq = ``;
        if (type === 'Deceased') {
            graphqlq += `
            update_Condolences(where: {InMemoryOf: {_eq: ${ID}}}, _set: {InMemoryOf: ${newNode}}) {
                affected_rows
            }
            update_Donations(where: {InMemoryOf: {_eq: ${ID}}}, _set: {InMemoryOf: ${newNode}}) {
                affected_rows
            }
            `
        }

        if (type === 'Charity') {
            graphqlq += `
            update_Donations(where: {CharityID: {_eq: ${ID}}}, _set: {CharityID: ${newNode}}) {
                affected_rows
            }
            `
        }

        apolloClient
            .mutate({
                mutation: gql`
                  mutation mutateAttributetoNew {
                    ${graphqlq}
                }`
            })
            .then(result => { 
                setUpdatingDB(0)
                if ((type === 'Deceased' && (result.data[`update_Condolences`].affected_rows || result.data[`update_Donations`].affected_rows))
                    || (type === 'Charity' && (result.data[`update_Donations`].affected_rows))) {
                    // action successful
                    setMessage('Entries Re-assigned. Deleting...')
                    setStatus('green')
                    deleteOnly(ID)
                } else {
                    setStatus('red')
                    setMessage('There was an error deleting this')
                }
            });
    }

    const deleteOnly = (ID) => {
        apolloClient
            .mutate({
                mutation: gql`
                mutation mutateDeleteEntry {
                    delete_${type}(where: {ID: {_eq: ${ID}}}) {
                        affected_rows
                    }
                }`
            })
            .then(result => { 
                setUpdatingDB(0)
                if (result.data[`delete_${type}`].affected_rows) {
                    // action successful
                    setMessage('Entry deleted. Redirecting...')
                    setTimeout(() => {
                        history.push(`/${prettifyTypes(type)}`)
                    }, 900)
                    setStatus('green')
                } else {
                    setStatus('red')
                    setMessage('There was an error deleting this')
                }
            });
    }

    const prettifyTypes = (type) => {
        if (type === 'Deceased') {
            return 'Obituaries'
        }
        if (type === 'Charity') {
            return 'Charities'
        }
        return type
    }

    return (
        <Layout
            title={`Deleting ${prettifyTypes(type)}...`}
        >
            {updatingDB ? <UpdatingNotification /> : null}
            <div className="tabular-data tabular-column">
                <div>
                    <h4><em>Deleting {prettifyTypes(type)} with ID of {ID}</em></h4>
                    {message ? (
                        <div className={`notification ${status}`}>
                            {message}
                        </div>
                    ) : null}
                    {attributionRequired ? (
                        <>
                        {type === 'Deceased' ? (
                            <div className="col">
                                <h4>Select {prettifyTypes(type)} to attribute to</h4>
                                <EditableField
                                    value={newNode}
                                    values={deceaseds}
                                    update={(e) => setNewNode(e.target.value)}
                                    editable={true}
                                    type={'select_map'}
                                    removeMe={ID}
                                    save={() => null}
                                >
                                    {newNode ? noNullValues(getRowByID(newNode, deceaseds).Name) : 'Select'}
                                </EditableField>
                            </div>
                        ) : null}
                        {type === 'Charity' ? (
                            <div className="col">
                                <h4>Select {prettifyTypes(type)} to attribute to</h4>
                                <EditableField
                                    value={newNode}
                                    values={charities}
                                    update={(e) => setNewNode(e.target.value)}
                                    editable={true}
                                    type={'select_map'}
                                    removeMe={ID}
                                    save={() => null}
                                >
                                    {newNode ? noNullValues(getRowByID(newNode, charities).Name) : 'Select'}
                                </EditableField>
                            </div>
                        ) : null}
                        </>
                    ) : null}

                    {condolences ? (
                        <div className="col">
                            <h4>List of Condolences Currently Attached</h4>
                            <div className="editable-field">
                                {condolences.map((node) => (
                                    <span>#{node.ID} - {node.FirstName} {node.LastName}</span>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    {donations ? (
                        <div className="col">
                            <h4>List of Donations Currently Attached</h4>
                            <div className="editable-field">
                                {donations.map((node) => (
                                    <span>#{node.ID} - {node.FirstName} {node.LastName}</span>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    <br />
                    <button className="button primary" onClick={deleteWithDependents}>Delete</button>
                </div>
            </div>
        </Layout>
    )
}