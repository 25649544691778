import React, { useState, useEffect } from 'react';
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import IconEdit from '../assets/images/icon_edit.svg'
import Layout from '../layout/Layout';
import { NavLink } from 'react-router-dom'
import { noNullValues, getSelectedNodeList } from '../helper/data-helper'

export default function Charities() {

    const [nodeList, setnodeList] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        apolloClient
            .query({
                query: gql`
                query queryCharities {
                    Charity(order_by: {Name: asc}) {
                        AddressLine1
                        AddressLine2
                        City
                        ContactFirstName
                        ContactLastName
                        Country
                        Email
                        Fax
                        ID
                        Name
                        Phone
                        PostalCode
                        Province
                        TollFreePhone
                        Website
                      }
                }`
            })
            .then(result => { 
                setnodeList(result.data.Charity);
            });
    }, []);

    const onSearch = (term) => {
        setSearchTerm(term)
    }

    let filteredList = nodeList;
    if (nodeList && searchTerm) {
        filteredList = nodeList.filter((data) => (data.Name && data.Name.includes(searchTerm)) || (data.AddressLine1 && data.AddressLine1.includes(searchTerm)) || (data.AddressLine2 && data.AddressLine2.includes(searchTerm)) || (data.City && data.City.includes(searchTerm)) || (data.Province && data.Province.includes(searchTerm)) || (data.Fax && data.Fax.includes(searchTerm)) || (data.Phone && data.Phone.includes(searchTerm)) || (data.Website && data.Website.includes(searchTerm)))
    }

    const onSelectAll = () => {
        if (!selectAll) {
            setSelectAllNodes(true)
            setSelectAll(true)
        } else {
            setSelectAllNodes(false)
            setSelectAll(false)
        }
    }

    const setSelectAllNodes = (state) => {
        let newnodeList = JSON.parse(JSON.stringify(nodeList))
        newnodeList.map((data, node) => {
            newnodeList[node].selected = state;
        })
        setnodeList(newnodeList)
    }

    const selectNode = (node) => {
        let newnodeList = JSON.parse(JSON.stringify(nodeList))
        if (!newnodeList[node].selected) {
            newnodeList[node].selected = true;
        } else {
            newnodeList[node].selected = false;
        }
        setnodeList(newnodeList)
    }

    return (
        <Layout
            title={'Charities'}
            search={true}
            onSearch={onSearch}
            addNew={'addNew/Charity'}
            onExport={() => getSelectedNodeList(nodeList)}
        >
            <div className="tabular-data three-col">
                <div className="row row-heading">
                    <div className="col small">
                        <input type="checkbox" onChange={onSelectAll}></input>
                    </div>
                    <div className="col medium">
                        <h4>ID</h4>
                    </div>
                    <div className="col">
                        <h4>Name</h4>
                    </div>
                    <div className="col">
                        <h4>Address</h4>
                    </div>
                    <div className="col">
                        <h4>City</h4>
                    </div>
                    <div className="col">
                        <h4>Phone</h4>
                    </div>
                    <div className="col small">
             
                    </div>
                </div>
                {nodeList ? filteredList.map((data, i) => {

                    return (
                        <div className="row">
                            <div className="col small">
                                <input type="checkbox" onChange={(e) => selectNode(i)} checked={data.selected ? 'checked' : false}></input>
                            </div>
                            <div className="col medium">
                                <p># {noNullValues(data.ID)}</p>
                            </div>
                            <div className="col">
                                <p>{noNullValues(data.Name)}</p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.AddressLine1)}
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.City)}
                                </p>
                            </div>
                            <div className="col">
                                <p>
                                {noNullValues(data.Phone) || noNullValues(data.TollFreePhone)}
                                </p>
                            </div>
                            <div className="col small">
                                <NavLink exact={true} activeClassName='is-active' to={`charity/${data.ID}`}><img src={IconEdit} className="edit" /></NavLink>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
        </Layout>
    )
}