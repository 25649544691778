import './assets/App.css';
import logo from './assets/images/logo.png'
import Helmet from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Sidebar from './layout/Sidebar';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Obituaries from './pages/Obituaries';
import Obituary from './pages/Obituary';
import Charities from './pages/Charities';
import Charity from './pages/Charity';
import Donations from './pages/Donations';
import Donation from './pages/Donation';
import Condolences from './pages/Condolences';
import Condolence from './pages/Condolence';
import AddNew from './pages/AddNew';
import AddNewSubmission from './pages/AddNewSubmission';
import DeleteEntry from './pages/DeleteEntry';
import DeleteEntryWithChildren from './pages/DeleteEntryWithChildren';

const login = window.location.href.includes('/login')

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>MacLean Funeral Home Portal</title>
        </Helmet>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div className="container">
          {!login ? <Sidebar /> : null}
          <div className={`main-content ${login ? 'login' : ''}`}>
            <Switch>
                <Route path="/donations">
                  <Donations />
                </Route>
                <Route path="/charities">
                  <Charities />
                </Route>
                <Route path="/charity/:ID">
                  <Charity />
                </Route>
                <Route path="/obituaries">
                  <Obituaries />
                </Route>
                <Route path="/obituary/:ID">
                  <Obituary />
                </Route>
                <Route path="/condolences">
                  <Condolences />
                </Route>
                <Route path="/condolence/:ID">
                  <Condolence />
                </Route>
                <Route path="/donations">
                  <Donations />
                </Route>
                <Route path="/donation/:ID">
                  <Donation />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/deleteEntry/:type/:ID">
                  <DeleteEntry />
                </Route>
                <Route path="/deleteEntryWithChildren/:type/:ID">
                  <DeleteEntryWithChildren />
                </Route>
                <Route path="/addNew/:type">
                  <AddNew />
                </Route>
                <Route path="/addNewSubmission/:type">
                  <AddNewSubmission />
                </Route>
                <Route path="/">
                  <Dashboard />
                </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
