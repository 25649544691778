import React, { useState } from 'react'
import axios from "axios";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import apolloClient from '../apollo/apollo-client';
import { gql } from '@apollo/client/core';
import { ExportToCsv } from 'export-to-csv';

export const noNullValues = (str) => {
    if (str && str !== 'NULL') {
        if (str instanceof Date) {
            return formatDate(str).toString();
        }
        return str
    }
    return null;
}

export const EditableField = ({save, type, value, values, ifnull, update, editable = true, removeMe, children}) => {
    const [editing, setEditing] = useState(0)
    
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEditing(0)
            save(1)
        }
    }

    const onSelect = (e) => {
        setEditing(0)
        save(1)
    }

    if (removeMe) {
        values = values.filter((option) => {
            return Number(option.ID) !== Number(removeMe)
        })
    }

    return (
        <div className="editable-field">
            {!editing ? (
                <span className="value-label" onClick={() => editable ? setEditing(1) : null}>{children && children !== ' ' ? children : 'None'}</span>
            ) : null}
            {editable && editing ? (
                <span className="value-field">
                    {type === 'textarea' ? (
                        <textarea onChange={update} onKeyDown={onKeyDown}>
                            {value === 'NULL' ? '' : value}
                        </textarea>
                    ) : null}
                    {type === 'date' ? (
                        <DatePicker selected={value} onChange={update} onSelect={onSelect}  dateFormat="yyyy/MM/dd" />
                    ) : null}
                    {type === 'select' ? (
                        <select value={value === 'NULL' ? '' : value} onChange={(e) => {update(e); onSelect(e); }}>
                            <option value=''>Select a value</option>
                            {values.map((option) => {
                                return (
                                    <option value={option}>{option}</option>
                                )
                            })}
                        </select>
                    ) : null}
                    {type === 'select_map' ? (
                        <select value={value === 'NULL' ? '' : value} onChange={(e) => {update(e); onSelect(e); }}>
                            <option value=''>Select a value</option>
                            {values.map((option) => {
                                return (
                                    <option value={option.ID}>{option.Name}</option>
                                )
                            })}
                        </select>
                    ) : null}
                    {!type ? (
                        <input type="text" value={value === 'NULL' ? '' : value} onChange={update} onKeyDown={onKeyDown} />
                    ) : null}
                    
                </span>
            ) : null}
        </div>
    )
}

export const SelectField = ({label, value, values, update}) => {    

    return (
        <div className="select-field">
            {values ? (
                <select value={value === 'NULL' ? '' : value} onChange={(e) => { update(e); }}>
                    <option value=''>{label}</option>
                    {values.map((option) => {
                        return (
                            <option value={option.ID}>{option.Name}</option>
                        )
                    })}
                </select>
            ) : null}
        </div>
    )
}

export const UpdatingNotification = () => (
    <div className="notification green">
        <span>Saving updates...</span>
    </div>
)

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


export const getRowByID = (ID, rows) => {
    let found = false;
    if (rows) {
        rows.map((row) => {
            if (Number(row.ID) === Number(ID)) {
                found = row;
            }
        })
    }
    return found;
}

export const getObituaries = async (callback) => {
    apolloClient
        .query({
            query: gql`
            query queryDeceased {
                Deceased(order_by: {Name: asc}) {
                    ID
                    Name
                  }
            }`
        }).then(result => { 
            const data = result.data.Deceased;
            callback(data);
        });
}

export const getSelectedNodeList = (nodeList) => {
    let newnodeList = JSON.parse(JSON.stringify(nodeList))
    return newnodeList.filter((node) => {
        if (node.selected) {
            return node;
        }
    })
}

export const sortNodeListForExport = (nodeList) => {
    let newnodeList = JSON.parse(JSON.stringify(nodeList))
    return newnodeList.sort((a, b) => {
        var charity1 = a.Charity.Name.toLowerCase();
        var charity2 = b.Charity.Name.toLowerCase();

        var name1 =  a.FirstName.toLowerCase() + a.LastName.toLowerCase();
        var name2 = b.FirstName.toLowerCase() + b.LastName.toLowerCase();

        if (charity1 < charity2) return -1;
        if (charity1 > charity2) return 1;
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    })
/*     return newnodeList.filter((node) => {
        if (node.selected) {
            return node;
        }
    }) */
}

export const exportCSV = (data) => {
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };
    const cleanData = sanitizeDataForExport(data);
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(cleanData);
}

const sanitizeDataForExport = (data) => {
    return data.map((item) => {
        delete item.__typename
        delete item.selected
        if (item.Deceased) {
            item.Deceased = item.Deceased.Name
        }
        const id = item.ID
        const name = item.Name ? item.Name : `${item.FirstName} ${item.LastName}`
        delete item.ID
        delete item.Name
        return {
            ID: id,
            Name: name,
            ...item
        }
    })
}

export const exportPDF = (template, data) => {
    axios.post(
        `/generatePDF/${template}`,
        data,
    )
    .then(response => {
        if (response.data === "Success") {
            // document generation was successful,
            // update the db to store that it was
            // exported
            window.open('/downloadPDF');
        }
     })
     .catch(error => console.log(error));
}